import { browser } from '$app/env';
import { getSdk } from '../../gen/graphql';

export const CMS_HOST = import.meta.env.VITE_CMS_HOST as string;
export const CMS_SECRET_TOKEN = import.meta.env.VITE_CMS_GRAPHQL_SECRET_PRIVATE_TOKEN as string;
console.info('Environment: ', import.meta.env.MODE);

export const CMS_API_ENDPOINT = `${CMS_HOST}/api`;

let token: string;

// this function only runs for client side navigation, SSR is handle in hooks.ts
if (browser) {
    const m = document.location.href.match(/\btoken=([^&]+)/);
    token = m ? m[1] : '';
}

function hash(str: string): string {
    str = str.replace(/\r/g, '');
    let hash = 5381;
    let i = str.length;

    while (i--) hash = ((hash << 5) - hash) ^ str.charCodeAt(i);
    return (hash >>> 0).toString(36);
}

export function buildQuery(
    queryString: string,
    variables: Record<string, string | number>,
    options: Record<string, string | number>
): { url: string; options: any } {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
    /** Check if a Authorization header got passed through the options. If yes, set it. */
    if (options && options['X-Craft-Authorization']) {
        headers['X-Craft-Authorization'] = options['X-Craft-Authorization'];
    }

    const queryUrl =
        CMS_API_ENDPOINT +
        (token ? `?myHash=${hash(queryString)}&token=${token}` : `?myHash=${hash(queryString)}`);
    const fetchOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ query: queryString, variables }),
    };
    return { url: queryUrl, options: fetchOptions };
}

export const requestContent = async function (
    queryString: string,
    variables: Record<string, string | number>,
    graphQLOptions: Record<string, string | number>,
    fetchFn: typeof fetch
): Promise<Record<string, unknown> | null> {
    const { url, options } = buildQuery(queryString, variables, graphQLOptions);
    if (typeof window !== 'undefined') fetchFn = fetch;

    try {
        const resp = await fetchFn(url, options);
        const gqlResp = await resp.json();

        // Handle GraphQL level errors:
        if (gqlResp.errors) {
            console.error('============= GraphQL Level Error START =============');
            gqlResp.errors.forEach((error) => {
                console.error(error);
            });
            console.error(`============= GraphQL Level Error END ===============`);
            return undefined;
        }
        return gqlResp.data;
    } catch (error) {
        // Handle network level errors:
        console.error(`Network error while executing GraphQL query:\n ${error}`);
        return undefined;
    }
};

export const privateServerSDK = (fetchFn: typeof fetch) => {
    function requester(queryString, variables?, options?) {
        return requestContent(
            queryString,
            variables,
            { 'X-Craft-Authorization': `Bearer ${CMS_SECRET_TOKEN}` },
            fetchFn
        );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getSdk(requester);
};

export const getPublicFrontendSDK = (fetchFn: typeof fetch) => {
    function requester(queryString, variables?, options?) {
        return requestContent(queryString, variables, options, fetchFn);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getSdk(requester);
};
